import React from 'react'
import {graphql} from 'gatsby'
import ProjectTeaser from '../components/projectTeaser/projectTeaser'

import Layout from '../components/layout/layout'

const Project = ({data}) => {
  if (data.allSanityProject.edges.lenght < 1) return null
  const project = data.allSanityProject.edges[0].node

  return (
    <Layout className='template-project'>
      <div className='container-fluid'>
        <ProjectTeaser project={project} />
      </div>
    </Layout>
  )
}

export default Project

export const query = graphql`
  query ProjectPageQuery($id: String) {
    allSanityProject(filter: {id: {eq: $id}}) {
      edges {
        node {
          id
          facts {
            finishedAt
            location
            projectCategory {
              title
            }
            squareMeter
          }
          images {
            additionalImg {
              asset {
                fluid {
                  src
                }
              }
              hotspot {
                height
                width
                x
                y
              }
            }
            mainImg {
              asset {
                fluid {
                  src
                }
              }
              hotspot {
                x
                y
                height
                width
              }
            }
          }
          slug {
            current
          }
          title
          fileDownload {
            asset {
              url
            }
          }
        }
      }
    }
  }
`
